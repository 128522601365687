// Generated by Framer (c01e615)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["anaLy3p7a"];

const variantClassNames = {anaLy3p7a: "framer-v-jp57a7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, rirauUvTa: image ?? props.rirauUvTa ?? {src: new URL("assets/512/RmVj1SOnm6GYy0XQxvkrU086kwE.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/RmVj1SOnm6GYy0XQxvkrU086kwE.jpg", import.meta.url).href} 512w, ${new URL("assets/RmVj1SOnm6GYy0XQxvkrU086kwE.jpg", import.meta.url).href} 800w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, rirauUvTa, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "anaLy3p7a", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-OeJxK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 266.5, intrinsicWidth: 400, pixelHeight: 533, pixelWidth: 800, sizes: "min(400px, 100vw)", ...toResponsiveImage(rirauUvTa)}} className={cx("framer-jp57a7", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"anaLy3p7a"} ref={ref} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-OeJxK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OeJxK .framer-1vfohm1 { display: block; }", ".framer-OeJxK .framer-jp57a7 { height: 267px; overflow: visible; position: relative; width: 400px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 266.5
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"rirauUvTa":"image"}
 */
const FrameryadliIU6k: React.ComponentType<Props> = withCSS(Component, css, "framer-OeJxK") as typeof Component;
export default FrameryadliIU6k;

FrameryadliIU6k.displayName = "Image";

FrameryadliIU6k.defaultProps = {height: 266.5, width: 400};

addPropertyControls(FrameryadliIU6k, {rirauUvTa: {__defaultAssetReference: "data:framer/asset-reference,RmVj1SOnm6GYy0XQxvkrU086kwE.jpg?originalFilename=176529ed9794cbba62f1903f0e2bd719f6586ffd9ca1c6de49fd62d1edcbcb89.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FrameryadliIU6k, [])